@import "variables/colors";
@import "variables/sizes";

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import "~lightbox2/dist/css/lightbox.min.css";
$bootstrap-icons-font-dir: "~bootstrap-icons/font/fonts";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import '~material-design-icons/iconfont/material-icons.css';
@import '~rangeslider.js/dist/rangeslider.css';

@import "views/main";
@import "views/history";