body {
  transition: height .3s ease;
}

.main.index {
  pre.sf-dump, pre.sf-dump .sf-dump-default {
    z-index: 5;
  }

  .card {
    transition: background-color .5s ease-in-out, color .5s ease-in-out;
    background: linear-gradient(to left, rgba($gray-700, .2) 0%, rgba($gray-800, .7) 0%);
    border-radius: 25px;
    border-color: transparent;

    @for $i from 1 through 100 {
      $j: calc(percentage(100 - $i) / 100);
      &.brightness-#{$i} {
        background: linear-gradient(to left, rgba($gray-700, .2) #{$j}, rgba($gray-800, .7) #{$j});
      }
    }

    [type="range"] {
      appearance: none;
      width: 100%;
      height: auto;
      margin: 0;
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    [type="range"] ~ .rangeslider {
      appearance: none;
      width: 100%;
      height: 20px;
      margin: 0;
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .icon.error {
      transform: translate(0, -50%);
      position: absolute;
      top: 50%;
      right: 10px;
    }

    &.room {
      .room-icon {
        font-size: 1.25rem;
        margin-right: .5rem;
      }

      .open {
        border-radius: 50%;
        background-color: $body-bg;
        transition: transform .1s ease;
        cursor: pointer;
        font-size: 30px;
        transform: translate(0, -50%);
        position: absolute;
        top: 50%;
        right: 10px;
      }

      .icon.error {
        transform: translate(0, -50%);
        position: absolute;
        top: 50%;
        right: 50px;
      }

      &[data-open='false'] {
        .open {
          transform: translate(0, -50%) rotate(180deg);
        }
      }
    }

    &[data-state="on"] {
      color: $yellow-300;
      background: linear-gradient(to left, rgba($yellow-300, .2) 0%, rgba($yellow-400, .3) 0%);

      @for $i from 1 through 100 {
        $j: calc(percentage(100 - $i) / 100);
        &.brightness-#{$i} {
          background: linear-gradient(to left, rgba($yellow-300, .2) #{$j}, rgba($yellow-400, .3) #{$j});
        }
      }

      .off, .unavailable {
        display: none;
      }
    }

    &[data-state="off"] {
      .on, .unavailable {
        display: none;
      }
    }

    &[data-state="unavailable"] {
      background: $gray-700;
      color: rgba($gray-200, .5);

      .on, .off {
        display: none;
      }

      input[type='range'] {
        display: none;
      }
    }
  }
}

.material-icons {
  vertical-align: bottom;
}